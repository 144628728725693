import React from 'react';
import homeFeaturePost from "../assets/images/blog/02_home_feature_post.jpg";
import BlogPostList from "../component/homeFeaturePost";
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

const LatestPostsArea = () => {
    const { t } = useTranslation();

    return (
        <div className="latest-posts-area style-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center align-items-center">
                            <span className="short-title">{t('latestPosts.title')}</span>
                            <h2 className="title">{t('latestPosts.exploreWithUs')}</h2>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="post-card style-three">
                            <div className="image">
                                <span className="image-overlay"></span>
                                <img loading="lazy" src={homeFeaturePost} alt="post-1"/>
                            </div>
                            <div className="content">
                                <h2 className="title">
                                    <Link to={'/'}>{t('blogPost.title')}</Link>
                                </h2>
                                <div className="post-meta">
                                    <span>{t('blogPost.date')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <BlogPostList language={localStorage.getItem('language')}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LatestPostsArea;
