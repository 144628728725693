import React from 'react';
import ServiceCard from './ServiceCard'; // Import ServiceCard component
import ServiceItem1 from "../assets/images/service/service-item-1.jpg"
import Transport from "../assets/images/icon/transport.png";
import HomeDelivery from "../assets/images/icon/home-delivery.png";
import Tracking from "../assets/images/icon/tracking.png";
import Payment from "../assets/images/icon/payment.png";
import Search from "../assets/images/icon/search.png";
import { useTranslation } from "react-i18next";

const ServiceArea = () => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook

    // Function to determine if the current language is English
    const isEnglish = i18n.language === 'en';

    // Object containing English and Arabic translations for service cards
    const serviceCards = {
        en: [
            {
                "image": "ServiceItem1",
                "IconImage": Transport,
                "title": "E-commerce",
                "description": "We offer integrated shipping solutions, including receiving goods from suppliers, temporary storage, packaging, and fast delivery to customers. Our services are designed to facilitate online selling and enhance customer satisfaction."
            },
            {
                "image": "ServiceItem1",
                "IconImage": HomeDelivery,
                "title": "Storage Services",
                "description": "We offer flexible and secure storage services that allow you to keep your goods in our warehouses equipped with the latest technologies to ensure the safety and protection of your inventory."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Tracking,
                "title": "Integrated Logistics Services",
                "description": "Comprehensive logistics solutions including planning, execution, and management of logistics operations to improve supply chain efficiency and reduce costs."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Payment,
                "title": "Packaging and Labeling Services",
                "description": "We provide custom packaging and labeling services to ensure the arrival of your products in excellent condition, while adhering to high quality and safety standards."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Search,
                "title": "Fast Shipping Service",
                "description": "We offer fast shipping service that ensures the arrival of your shipment in the shortest possible time."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Payment,
                "title": "Frozen Shipping",
                "description": "We offer specialized services for shipping frozen goods and commodities that require temperature control. Using advanced cooling technologies, we ensure the safe arrival of your food, medical, and other frozen products while maintaining their quality."
            }
            // Add more service cards in English
        ],
        ar: [
            {
                "image": "ServiceItem1",
                "IconImage": Transport,
                "title": "التجارة الألكترونية",
                "description": "نقدم حلول شحن متكاملة، تشمل استلام البضائع من الموردين، التخزين المؤقت، التغليف، والتوصيل السريع إلى العملاء. خدماتنا مصممة لتسهيل عملية البيع عبر الإنترنت وتعزيز رضا العملاء."
            },
            {
                "image": "ServiceItem1",
                "IconImage": HomeDelivery,
                "title": "خدمات التخزين",
                "description": "نقدم خدمات تخزين مرنة وآمنة تتيح لك الاحتفاظ ببضائعك في مستودعاتنا المجهزة بأحدث التقنيات لضمان سلامة وحماية مخزونك."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Tracking,
                "title": "الخدمات اللوجستية المتكاملة",
                "description": "حلول لوجستية متكاملة تشمل التخطيط، التنفيذ، وإدارة العمليات اللوجستية لتحسين كفاءة سلسلة التوريد وتقليل التكالف."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Payment,
                "title": "خدمات التغليف والتعبئة",
                "description": "نقدم خدمات تغليف وتعبئة مخصصة تضمن وصول منتجاتك بحالة ممتازة، مع مراعاة معايير الجودة والسلامة العالية."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Search,
                "title": "خدمة الشحن السريع",
                "description": "نقدم خدمة الشحن السريع التي تضمن وصول شحنتك في أقصر وقت ممكن."
            },
            {
                "image": "ServiceItem1",
                "IconImage": Payment,
                "title": "الشحن المبرد",
                "description": "نقدم خدمات متخصصة لشحن المجمدات والبضائع التي تتطلب التحكم في درجة الحرارة. باستخدام تقنيات التبريد المتقدمة، نضمن وصول منتجاتكم الغذائية والطبية وغيرها من المجمدات بأمان وحفاظ على جودتها."
            }
        ]
    };

    // Get the service cards based on the current language
    const currentServiceCards = isEnglish ? serviceCards.en : serviceCards.ar;

    return (
        <div className="service-area style-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center align-items-center mt-10">
                            <span className="short-title">{ t('ourServices.title') }</span>
                            <h2 className="title">{ t('ourServices.subtitle') }</h2>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* Display service cards based on the current language */}
                    {currentServiceCards.map((service, index) => (
                        <ServiceCard
                            key={index}
                            image={service.image}
                            IconImage={service.IconImage}
                            title={service.title}
                            description={service.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ServiceArea;
