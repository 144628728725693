import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import PageBreadcrumb from "../component/PageBreadcrumb";
import ContactInfoArea from "../component/ContactInfoArea";
import ContactForm from "../component/ContactForm";
import SquareShapeWrapper from "../component/SquareShapeWrapper";
import {useTranslation} from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();
    const { pageTitle, breadcrumbLinks } = t('contactPage', { returnObjects: true });


    return (
        <>
            <Header />
            <PageBreadcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
            <ContactInfoArea />
            <SquareShapeWrapper position={'counter'}/>
            <ContactForm />
            <SquareShapeWrapper position={'appointment'}/>
            {/*<ContactMap />*/}
            <Footer />

        </>
    );
};

export default Contact;
