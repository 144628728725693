import React, { useState } from 'react';
import SubscribeFormBg from "../assets/images/section-bg/subscribe-form-bg.png";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const SubscribeFormArea = ({ handleRequest }) => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook
    const [trackingCode, setTrackingCode] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleRequest(trackingCode);
        setTrackingCode('');
    }

    return (
        <div className="subscribe-form-area style-1">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="subscribe-area">
                            <div className="shape-bg">
                                <img loading="lazy" src={SubscribeFormBg} alt="shape" />
                            </div>
                            <div className="triangle-shape"></div>
                            <div className="subscribe-inner-area">
                                <div className="content-wrapper">
                                    <h4 className="short-title">{t('subscribeForm.title')}</h4>
                                    <h2 className="subscribe-title">
                                        {t('subscribeForm.subtitle')}
                                    </h2>
                                    <p className="text">{t('subscribeForm.description')}</p>
                                </div>
                                <div className="subscribe-form-wrapper">
                                    <div className="subscribe-form-widget">
                                        <form onSubmit={handleSubmit} className="comment-form">
                                            <div className="mc4wp-form-fields">
                                                <div className="single-field">
                                                    <input type="text"
                                                           placeholder={t('subscribeForm.placeholder')}
                                                           value={trackingCode}
                                                           onChange={(e) => {
                                                            setTrackingCode(e.target.value);
                                                           }}
                                                    />
                                                </div>
                                                <button className="submit-btn" type="submit">
                                                    <i className="fa-solid fa-search" style={{ fontSize: `35px` }}></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubscribeFormArea;
