import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import PageBreadcrumb from "../component/PageBreadcrumb";
import ContactInfoArea from "../component/ContactInfoArea";
import SquareShapeWrapper from "../component/SquareShapeWrapper";
import TrackTraceForm from "../component/TrackTraceForm";
import {useTranslation} from "react-i18next";

const TrackTrace = ({handleRequest}) => {

    const { t } = useTranslation();
    const { pageTitle, breadcrumbLinks } = t('trackTracePage', { returnObjects: true });

    return (
        <>
            <Header />
            <PageBreadcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
            <ContactInfoArea />
            <SquareShapeWrapper position={'counter'}/>
            <TrackTraceForm handleRequest={handleRequest}/>
            <Footer />
        </>
    );
};

export default TrackTrace;
