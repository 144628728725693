import React from 'react';

const SquareShapeWrapper = (props) => {

    return (
        <div className="squre-shape-wrapper">
            <div className={`${props.position}-squre-shape`}></div>
        </div>
    );
}

export default SquareShapeWrapper;
