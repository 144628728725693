import React, { useEffect, useRef, useState } from 'react';

const CounterUp = ({ start, end, delay }) => {
    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        observer.observe(elementRef.current);

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isVisible) {
            const startCount = () => {
                const increment = end / (1000 / delay);
                let currentValue = start;

                const updateCounter = () => {
                    if (elementRef.current) { // Check if elementRef.current is not null
                        currentValue += increment;
                        if (currentValue <= end) {
                            elementRef.current.innerText = Math.ceil(currentValue);
                            requestAnimationFrame(updateCounter);
                        } else {
                            elementRef.current.innerText = end;
                        }
                    }
                };

                updateCounter();
            };

            const timer = setTimeout(startCount, delay);

            return () => clearTimeout(timer);
        }
    }, [isVisible, start, end, delay]);

    return <span ref={elementRef} />;
};

export default CounterUp;
