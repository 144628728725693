import React from 'react';

const PageBreadcrumb = ({ pageTitle, breadcrumbLinks }) => {
    return (
        <div className="page-breadcrumb-area page-bg" style={{backgroundImage: `url('images/section-bg/transportation-logistics.jpg')`}}>
            <div className="page-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-wrapper">
                            <div className="page-heading">
                                <h3 className="page-title">{pageTitle}</h3>
                            </div>
                            <div className="breadcrumb-list">
                                <ul>
                                    {breadcrumbLinks.map((link, index) => (
                                        <li key={index}><a href={link.url}>{link.label}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageBreadcrumb;
