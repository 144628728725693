import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import PageBreadcrumb from "../component/PageBreadcrumb";
import ServiceArea from "../component/ServiceArea";
import ProcessStepArea from "../component/ProcessStepArea";
import WhyChooseUs from "../component/WhyChooseUs";
import {useTranslation} from "react-i18next";

const Services = () => {

    const { t } = useTranslation();
    const { pageTitle, breadcrumbLinks } = t('contactPage', { returnObjects: true });


    return (
        <>
            <Header />
            <PageBreadcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
            <ServiceArea />
            <ProcessStepArea />
            <WhyChooseUs />
            <Footer />

        </>
    );
};

export default Services;
