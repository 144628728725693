import React from 'react';

const InfoCard = ({ icon, title, desc }) => {
    return (
        <div className="col-md-6 col-lg-3 p-lg-0" style={{marginBottom: '100px'}}>
            <div className="info-card">
                <div className="divider"></div>
                <div className="info-card-inner">
                    <div className="content-wrapper">
                        <div className="title-wrapper">
                            <div className="icon">
                                <img loading="lazy" src={icon} alt="support" />
                            </div>
                            <h2 className="title">{title}</h2>
                        </div>
                        <div className="content">
                            <p className="desc">{desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoCard;
