import React from 'react';

const TimeCardItem = ({ city, location, num, Link }) => {
    return (
        <div className="time-card-item">
            <div className="time-card-desc">
                <div className="title">
                    <h2>{city}</h2>
                </div>
                <div className="desc">
                    <p>{location}</p>
                </div>
            </div>
            <div className="circle-wrapper">
                <div className="time-card-circle"></div>
            </div>
            <div className="time-card-time">
                <a href={Link} target="_blank" className="time-card-date">{num}<i className="fa-solid fa-up-right-from-square m-2"></i></a>
            </div>
        </div>
    );
};

export default TimeCardItem;
