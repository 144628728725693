import React from 'react';
import Home1FeatureSlider1 from "../assets/images/slider/home-1-feature-slider-1.png";
import {useTranslation} from "react-i18next";

const SliderItem = ({backgroundImage, HomeFeatureSlider, title, shortTitle, description}) => {
    return (
        <div className="single-slider-wrapper">
            <div className="single-slider" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="slider-overlay"></div>
                <div className="container h-100 align-self-center">
                    <div className="row h-100">
                        <div className="col-md-6 align-self-center order-2 order-md-1">
                            <div className="slider-content-wrapper">
                                <div className="slider-content">
                                    <span className="slider-short-title">{shortTitle}</span>
                                    <h1 className="slider-title">{title}</h1>
                                    <p className="slider-short-desc" style={{direction: 'rtl', textAlign:'center'}}>{}</p>
                                    {/*<div className="slider-btn-wrapper">*/}
                                    {/*    <a href="about.html" className="theme-btn">اقرأ المزيد<i className="fa-solid fa-angle-right"></i></a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 align-self-center order-1 order-md-2">
                            <div className="slider-image">
                                <img loading="lazy" src={HomeFeatureSlider} alt="feature image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderItem;
