import React, {useEffect, useState} from 'react';
import BarCodeIcon from '../assets/images/icon/barcode-reader.png';
import '../assets/css/scanner.css';
import QrCodeScanner from "../pages/QrCodeScanner";

const CameraScan = ({handleRequest}) => {
    const [isOpen, setIsOpen] = useState(false);

    const popupScanner = (e) => {
        e.preventDefault();
        setIsOpen(true);
    };

    const closeScanner = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleBodyClick = (e) => {
            if (!e.target.closest('.scanner') && !e.target.closest('.camera-scan') && !e.target.closest('.tracking-data')) {
                closeScanner();
            }
        };

        document.body.addEventListener('click', handleBodyClick);

        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, []);


    return (
        <>
            <div className="camera-scan" onClick={popupScanner}>
                <div className="camera-popup-scan h-100">
                    <div className="camera-popup-btn">
                        <a href="#" onClick={popupScanner} className="mfp-iframe video-play">
                            <img src={BarCodeIcon} alt="Barcode Icon"/>
                        </a>
                    </div>
                </div>
            </div>

            <div className={`scanner ${isOpen && 'show-scanner'}`}>
                <button className="btn btn-link close-tracking-data" style={{zIndex:'9'}} onClick={() => closeScanner()}><i
                    className="fal fa-times" aria-hidden="true"></i>
                </button>

                {isOpen && <QrCodeScanner handleRequest={handleRequest}/>}
            </div>
        </>
    );
};

export default CameraScan;