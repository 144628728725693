import React from 'react';
import TimeCardItem from './TimeCardItem'; // Import the TimeCardItem component
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Timeline = () => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook
    const branches = [
        { location: 'طريق الملك فهد - المربع', city: 'الرياض',   mapLink: 'https://maps.app.goo.gl/JzhqJV9CMz1J8nP37' },
        { location: 'طريق خريص - الأندلس',  city:'الرياض',  mapLink: 'https://maps.app.goo.gl/wVTP7fmzUG4HrBex5' },
        { location: 'إمام مسلم - معرض الشفاء - المروى',  city:"الرياض",  mapLink: 'https://maps.app.goo.gl/A8MszvyHbcxtR7eC9' },
        { location: 'طريق الدمام - الچندرية الخرج',  city:"الرياض",  mapLink: 'https://maps.app.goo.gl/ixLSAjS2SMPbE3Hn9' },
        { location: 'طريق الملك عبدالله - العلية خميس - مشيط',  city:"الخرج",  mapLink: 'https://maps.app.goo.gl/Tkm6PiGuUx8Z8WKeA' },
        { location: 'طريق الملك خالد - مقاطعة الحرير-أبها',  city:"الخرج",  mapLink: 'https://maps.app.goo.gl/2bmQJDhmzcXcKqLv8' },
        { location: ' كوبري المطار - طريق الملك فهد - جدة',  city:"أبها",  mapLink: 'https://maps.app.goo.gl/huJUnwjUeq5rMeNF9' },
        { location: 'طريق النزهة',  city:"جده",  mapLink: 'https://maps.app.goo.gl/huJUnwjUeq5rMeNF9' },
        { location: 'الجوهرة- الخبر',  city:"جده",  mapLink: 'https://maps.app.goo.gl/gGg6tDHjcio3imMu5' },
        { location: 'طريق مجلس التعاون - الخزامي - الدمام',  city:"الخبر",  mapLink: 'https://maps.app.goo.gl/WbEQgKNN48prQRB28' },
        { location: 'شارع المعارض - ضاحية الملك فهد - المدينة',  city:"الدمام",  mapLink: 'https://maps.app.goo.gl/mWN3yZQ6kpLq5kZ26' },
        { location: ' المعارض - العزيزية-الطائف',  city:"المدينه",  mapLink: 'https://maps.app.goo.gl/shTtzDKwv3yLRHbX6' },
        { location: 'طريق المطار - الفيصلية',  city:"الطائف",  mapLink: 'https://maps.app.goo.gl/UcZBX4Zj6HPuscon8' },
        { location: ' طريق مكة - ينبع',  city:"الطائف",  mapLink: 'https://maps.app.goo.gl/yZgc3atxYYsB89H19' },
        { location: ' معبر التفتيش الدوري والمطار - جدويل-جيزان',  city:"ينبع",  mapLink: 'https://maps.app.goo.gl/kfeHvD84Jwq472cKA' },
        { location: ' إشارة المعبوج - الزهور-صبيا',  city:"جيزان",  mapLink: 'https://maps.app.goo.gl/djZbKZiswmd9SN4U8' },
        { location: ' طريق الملك عبد العزيز-القصيم',  city:"صبيا",  mapLink: 'https://maps.app.goo.gl/gTBRZMfUrkNkGjWY6' },
        { location: ' طريق الملك عبد العزيز - هفوف',  city:"القصيم",  mapLink: 'https://maps.app.goo.gl/4TcZrsFiWGn1VXDf8' },
        { location: ' الاتصالات - المبرز',  city:"هفوف",  mapLink: 'https://maps.app.goo.gl/JY383xXwJ7eBpqqC9' }
    ];
    return (
        <div className="timeline-area style-1" style={{ direction: 'rtl' }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center align-items-center mb-70">
                            <span className="short-title">{t('timeline.titleShort')}</span>
                            <h2 className="title">{t('timeline.title')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12">
                        <div className="time-card-wrapper">
                            {branches.map((branch, index) => <TimeCardItem  city={branch.city} location={branch.location} num={t('timeline.location')} Link={branch.mapLink} />)}
                            <div className="time-card-arrow">
                                <i className="fa-solid fa-angle-down"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Timeline;
