// BlogArea.js
import React from 'react';
import BlogPost from './BlogPost';

const BlogArea = ({posts}) => {
    return (
        <div className="blog-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog-post">
                        {posts.map(post => (
                            <BlogPost key={post.id} post={post}/>
                        ))}
                        <div className="basic-pagination basic-pagination-2 mb-40">
                            <ul>
                                <li>
                           <span aria-current="page" className="page-numbers current">1</span>
                                </li>
                                <li><a className="page-numbers" href="#">2</a></li>
                                <li><a className="page-numbers" href="#">3</a></li>
                                <li><span className="page-numbers dots">…</span></li>
                                <li><a className="page-numbers" href="#">5</a></li>
                                <li>
                                    <a className="next page-numbers" href="#"
                                    ><i className="fa fa-arrow-right"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 order-1 order-lg-2">
                        <div className="sidebar">
                            <div className="widget widget_search">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Search</h4>
                                </div>
                                <form className="search-form" action="#" method="get">
                                    <input type="text" placeholder="Type and Search"/>
                                    <button type="submit"><i className="fas fa-search"></i></button>
                                </form>
                            </div>
                            <div className="widget widget_latest_post">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Recent Posts</h4>
                                </div>
                                <ul>
                                    <li>
                                        <div className="latest-post-thumb">
                                            <img loading="lazy" src="images/blog/rp-3.jpg" alt=""/>
                                        </div>
                                        <div className="latest-post-desc">
                                            <h3 className="latest-post-title">
                                                <a href="blog-details.html">Dike takiye bolte paro ei akash amar</a>
                                            </h3>
                                            <span className="latest-post-meta">April 12, 2023</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget widget_categories">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Categories</h4>
                                </div>
                                <ul>
                                    <li><a href="#">Transportation</a>23</li>
                                    <li><a href="#">Product Delivery</a>34</li>
                                    <li><a href="#">Transport Business</a>65</li>
                                    <li><a href="#">Logistic Support</a>12</li>
                                    <li><a href="#">World Travelling</a>32</li>
                                    <li><a href="#">Marketing</a>8</li>
                                </ul>
                            </div>
                            <div className="widget widget_tag_cloud">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Tags</h4>
                                </div>
                                <div className="tagcloud">
                                    <a href="#">Logistics</a>
                                    <a href="#">Transport</a>
                                    <a href="#">Go</a>
                                    <a href="#">Cargo</a>
                                    <a href="#">Freight</a>
                                    <a href="#">Ocean</a>
                                    <a href="#">Social Media</a>
                                    <a href="#">Google</a>
                                </div>
                            </div>
                            <div className="widget widget_instagram_feed">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Gallery</h4>
                                </div>
                                <div className="widget-instagram-feed">
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-1.jpg" alt="instagram photo" />
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-2.jpg" alt="instagram photo" />
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-3.jpg" alt="instagram photo" />
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-4.jpg" alt="instagram photo" />
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-5.jpg" alt="instagram photo" />
                                    </div>
                                    <div className="single-instagram-feed">
                                        <img loading="lazy" src="images/instagram/instagram-6.jpg" alt="instagram photo" />
                                    </div>
                                </div>
                            </div>
                            <div className="widget widget_social_profile">
                                <div className="widget-title-box">
                                    <h4 className="wp-block-heading">Social</h4>
                                </div>
                                <div className="social-profile">
                                    <a className="facebook" href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                    <a className="twitter" href="#"><i className="fa-brands fa-twitter"></i></a>
                                    <a className="linkedin" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                    <a className="instagram" href="#"><i className="fa-brands fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogArea;
