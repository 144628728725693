import React from 'react';

const ListItem = ({ text }) => {
    return (
        <div className="col-12 col-lg-6">
            <div className="list-item">
                <div className="icon">
                    <i className="fa-solid fa-check"></i>
                </div>
                <p className="text">{text}</p>
            </div>
        </div>
    );
};

export default ListItem;
