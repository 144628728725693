import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import EnglishImage from '../assets/images/icon/english.png';
import ArabicImage from '../assets/images/icon/arabic.png';
const Nav = () => {
    const location = useLocation();
    const { t,i18n } = useTranslation();

    const cl = (lng) => {
        i18n.changeLanguage(lng);
    };
    return (
        <nav id="main-menu" className="main-menu">
            <ul className="text-center mb-2">
                <li className={location.pathname === '/' ? 'active' : ''}>
                    <Link to="/">{t('home')}</Link>
                </li>
                <li className={location.pathname === '/about' ? 'active' : ''}>
                    <Link to="/about">{t('about')}</Link>
                </li>
                <li className={location.pathname === '/services' ? 'active' : ''}>
                    <Link to="/services">{t('services')}</Link>
                </li>
                {/*<li className={location.pathname === '/blogs' ? 'active' : ''}>*/}
                {/*    <Link to="/blogs">{t('blogs')}</Link>*/}
                {/*</li>*/}
                <li className={location.pathname === '/track-trace' ? 'active' : ''}>
                    <Link to="/track-trace">{t('track-trace')}</Link>
                </li>
                {/*<li className={location.pathname === '/branches' ? 'active' : ''}>*/}
                {/*    <Link to="/branches">{t('branches')}</Link>*/}
                {/*</li>*/}
                <li className={location.pathname === '/contact' ? 'active' : ''}>
                    <Link to="/contact">{t('contact')}</Link>
                </li>
                {i18n.language !== 'en' && <li style={{ cursor: 'pointer', marginRight: `10px`}} onClick={() => cl('en')}><img src={EnglishImage} width={`40px`} style={{height: '40px', borderRadius: '50%'}}/></li>}
                {i18n.language !== 'ar' && <li style={{ cursor: 'pointer', marginRight: `10px`}} onClick={() => cl('ar')}><img src={ArabicImage} width={`40px`}style={{height: '40px',borderRadius: '50%'}} /></li>}
            </ul>
        </nav>
    );
}

export default Nav;
