import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from "html5-qrcode";

const QrCodeScanner = ({ handleRequest }) => {
    const scannerRef = useRef(null);

    useEffect(() => {
        scannerRef.current = new Html5QrcodeScanner('scanner-reader', {
            qrbox: {
                width: 250,  // Adjusted width
                height: 250,  // Adjusted height
            },
            fps: 10,  // Increased fps for smoother scanning
        });

        const success = (result) => {
            handleRequest(result);
        };

        const error = (err) => {
            console.warn("QR code scan error:", err);
        };

        scannerRef.current.render(success, error);

        // Cleanup function
        return () => {
            if (scannerRef.current) {
                scannerRef.current.clear();
            }
        };
    }, [handleRequest]);

    return <div id='scanner-reader'></div>;
};

export default QrCodeScanner;
