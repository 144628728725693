import React from 'react';
import { useTranslation } from 'react-i18next';
import CounterItem from './CounterItem'; // Assuming CounterItem component is available
import CounterBg from '../assets/images/section-bg/counter-bg.jpg'; // Import the background image

const CounterUpArea = () => {
    const { t } = useTranslation();

    return (
        <div className="counter-up-area style-1 position-relative" style={{backgroundImage: `url('${CounterBg}')`}}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="counter-card">
                            <CounterItem endValue={90} title={t('counter_up.transfers')} description={t('counter_up.transfers_description')} />
                            <CounterItem endValue={230} title={t('counter_up.car_shipments')} description={t('counter_up.car_shipments_description')} />
                            <CounterItem endValue={500} title={t('counter_up.business_transactions')} description={t('counter_up.business_transactions_description')} />
                            <CounterItem endValue={10} title={t('counter_up.packaged_goods')} description={t('counter_up.packaged_goods_description')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CounterUpArea;
