import React from 'react';

const SocialMediaLinks = () => {
    return (
        <>
            <a href="https://www.facebook.com/profile.php?id=100064157496866&mibextid=LQQJ4d"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="https://x.com/smbexpress?s=11&t=juCkxMEAGsQk7Ux0vph9Hg"><i className="fa-brands fa-twitter"></i></a>
            <a href="https://www.instagram.com/smbexpress?igsh=MTl6c2RreGhkYWxneQ=="><i className="fa-brands fa-instagram"></i></a>
            <a href="https://www.tiktok.com/@smbexpress.ksa?_t=8krJSkNShKn&_r=1"><i className="fa-brands fa-tiktok"></i></a>
        </>
    );
};

export default SocialMediaLinks;
