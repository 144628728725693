import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from "../component/Header";
import Footer from "../component/Footer";
import PageBreadcrumb from "../component/PageBreadcrumb";
import AboutUsArea from "../component/AboutUsArea";
import CounterUpArea from "../component/CounterUpArea";
import Timeline from "../component/Timeline";

const About = () => {
    const { t } = useTranslation();
    const { pageTitle, breadcrumbLinks } = t('aboutPage', { returnObjects: true });

    return (
        <>
            <Header />
            <PageBreadcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
            <AboutUsArea />
            <CounterUpArea />
            <Timeline />
            <Footer />
        </>
    );
};

export default About;
