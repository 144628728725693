import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionItem from './AccordionItem';
import appointmentBg from '../assets/images/appointment/appointment-bg.jpg';

const AppointmentArea = () => {
    const { t } = useTranslation();
    const AccordionItems = t('AccordionItems', { returnObjects: true })
    return (
        <div className="appointment-area style-1 position-relative" style={{backgroundImage: `url(${appointmentBg})`}}>
            <div className="blue-overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <div className="comment-respond style-three bg-white p-50">
                            <div className="post-comments-title">
                                <h2>{t('appointment_area.title')}</h2>
                            </div>
                            <form action="#" className="comment-form">
                                <div className="row gx-2">
                                    <div className="col-md-6">
                                        <div className="single-input-field">
                                            <input name="name" type="text" placeholder={t('appointment_area.form_placeholder.name')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-field">
                                            <input name="email" type="email" placeholder={t('appointment_area.form_placeholder.email')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-field">
                                            <input name="number" type="number" placeholder={t('appointment_area.form_placeholder.phone_number')} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-input-field">
                                            <input name="subject" type="text" placeholder={t('appointment_area.form_placeholder.subject')} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-input-field">
                                            <textarea name="subject" type="text" placeholder={t('appointment_area.form_placeholder.comment')}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="theme-btn" type="submit">{t('appointment_area.submit_button')} <i className="fa-solid fa-angle-right"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="accordion-wrapper">
                            <div className="section-title mb-30">
                                <span className="short-title text-white">{t('appointment_area.faq_title')}</span>
                                <h2 className="title text-white">{t('appointment_area.faq_subtitle')}</h2>
                            </div>
                            <AccordionItem items={AccordionItems} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppointmentArea;
