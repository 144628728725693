import React from 'react';
const NotFoundPage = () => {
    return (
        <h2>
            Not Found
        </h2>
    );
};

export default NotFoundPage;
