import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactForm = () => {
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className="contact-form-area mb-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="contact-title">
                            <h2>{t('contact_form.send_message')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="comment-respond mt-45">
                            <form onSubmit={handleSubmit} className="comment-form">
                                <div className="row gx-2">
                                    <div className="col-xl-6">
                                        <div className="contacts-name">
                                            <input name="author" type="text" placeholder={t('contact_form.name')} required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contacts-email">
                                            <input name="email" type="email" placeholder={t('contact_form.email')} required />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contacts-name">
                                            <input name="phone" type="text" placeholder={t('contact_form.phone')} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contacts-name">
                                            <input name="subject" type="text" placeholder={t('contact_form.subject')} />
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="contacts-message">
                                            <textarea name="message" cols="20" rows="3" placeholder={t('contact_form.message_placeholder')} required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="theme-btn" type="submit">{t('contact_form.submit')} <span className="icon"><i className="fa-solid fa-angle-right"></i></span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
