import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ScrollToTopButton from "../component/ScrollToTopButton";
import Header from "../component/Header";
import Footer from "../component/Footer";
import SliderArea from "../component/SliderArea";
import FeatureArea from "../component/FeatureArea";
import AboutUsArea from "../component/AboutUsArea";
import CounterUpArea from "../component/CounterUpArea";
import SquareShapeWrapper from "../component/SquareShapeWrapper";
import ServiceArea from "../component/ServiceArea";
import SubscribeFormArea from "../component/SubscribeFormArea";
import ProcessStepArea from "../component/ProcessStepArea";
import LatestPostsArea from "../component/LatestPostsArea";
import AppointmentArea from "../component/AppointmentArea";

const Home = ({handleRequest}) => {

        return (
        <>
            <Header />
            <SliderArea />
            <FeatureArea />

            <AboutUsArea />
            <CounterUpArea />
            <SquareShapeWrapper position='counter'/>
            <ServiceArea />
            <SubscribeFormArea handleRequest={handleRequest}/>
            <ProcessStepArea />
            <SquareShapeWrapper position='appointment'/>
            <LatestPostsArea />
            <AppointmentArea />
            <Footer />
            <ScrollToTopButton />
        </>
    );
};

export default Home;
