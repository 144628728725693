import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            clearTimeout(timer);
            if (scrollTop > 100) {
                if (scrollTop > position) {
                    setShowButton(false);
                } else {
                    setShowButton(true);
                    timer = window.setTimeout(() => {
                        setShowButton(false);
                    }, 3000);
                }
                position = scrollTop;
            } else {
                setShowButton(false);
            }
        };

        let position = window.pageYOffset || document.documentElement.scrollTop;
        let timer;

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div id="scrollTop" className={`scrollup-wrapper ${showButton ? 'show' : ''}`}>
            <div className="scrollup-btn" onClick={scrollToTop}>
                <i className="far fa-angle-up"></i>
            </div>
        </div>
    );
};

export default ScrollToTopButton;
