// BlogPost.js
import React from 'react';

const BlogPost = ({ post }) => {
    return (
        <article className="single-post-item format-image">
            <div className="post-thumbnail">
                <a href={post.link}>
                    <img loading="lazy" src={post.image} alt={post.title} />
                </a>
            </div>
            <div className="post-content-wrapper">
                <div className="post-meta">
                    <span><i className="fa-light fa-folder-open"></i>{post.category}</span>
                    <span><i className="fa-regular fa-clock"></i>{post.date}</span>
                    <span><a href="#"><i className="fa-regular fa-comments"></i>{post.comments} Comments</a></span>
                </div>
                <h3 className="post-title">
                    <a href={post.link}>{post.title}</a>
                </h3>
                <div className="post-content">
                    <p>{post.excerpt}</p>
                </div>
                <div className="read-more">
                    <a href={post.link} className="theme-btn">Read More<i className="fa-solid fa-angle-right"></i></a>
                    {/* Social Share Component */}
                </div>
            </div>
        </article>
    );
};

export default BlogPost;
