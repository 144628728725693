import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoCard from './InfoCard'; // Import the InfoCard component
import Support from "../assets/images/icon/support.png";
import Courier from "../assets/images/icon/courier.png";
import WorldDistribution from "../assets/images/icon/world-distribution.png";
import Unboxing from "../assets/images/icon/unboxing.png";

const FeatureArea = () => {
    const { t } = useTranslation();

    return (
        <div className="feature-area style-1">
            <div className="container">
                <div className="feature-area-wrapper">
                    <div className="row">
                        {/* InfoCard components */}
                        <InfoCard
                            icon={Support}
                            title={t('feature_area.honesty')}
                            desc={t('feature_area.honesty_description')}
                        />
                        <InfoCard
                            icon={Courier}
                            title={t('feature_area.customer_focus')}
                            desc={t('feature_area.customer_focus_description')}
                        />
                        <InfoCard
                            icon={WorldDistribution}
                            title={t('feature_area.our_values')}
                            desc={t('feature_area.our_values_description')}
                        />
                        <InfoCard
                            icon={Unboxing}
                            title={t('feature_area.our_mission')}
                            desc={t('feature_area.our_mission_description')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeatureArea;
