import React, {useState} from 'react';
import '../assets/css/TrackingData.css';
import {useTranslation} from "react-i18next";


const TrackTraceForm = ({handleRequest}) => {

    const [trackingCode, setTrackingCode] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault()
        handleRequest(trackingCode);
        setTrackingCode('');
    }
    const { t } = useTranslation();

    return (
        <>
            <div className="contact-form-area mb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="contact-title">
                                <h2>{ t('subscribeForm.title') }</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="comment-respond mt-45">
                                <div className="subscribe-form-wrapper">
                                    <div className="subscribe-form-widget">
                                        <form onSubmit={handleSubmit} className="comment-form">
                                            <div className="mc4wp-form-fields">
                                                <div className="single-field">
                                                    <input type="text"
                                                           value={trackingCode}
                                                           onChange={(e) => {
                                                            setTrackingCode(e.target.value);
                                                           }}
                                                           placeholder={t('subscribeForm.placeholder')}
                                                    />
                                                </div>
                                                <button className="submit-btn" type="submit"><i
                                                    className="fa-solid fa-search" style={{fontSize: `35px`}}></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};

export default TrackTraceForm;
