import React from 'react';
import ProcessImg from "../assets/images/section-bg/process-img.png";
import ProcessStep from './ProcessStep';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook to access translations

const ProcessStepArea = () => {
    const { t } = useTranslation(); // Initialize useTranslation hook

    return (
        <div className="process-step-area style-1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center align-items-center mt-10">
                            <span className="short-title">{t('processSteps.title')}</span>
                            <h2 className="title text-white">
                                {t('processSteps.subtitle')}
                            </h2>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            {t('processSteps.steps', { returnObjects: true }).slice(0, 2).map((step, index) => (
                                <ProcessStep
                                    key={index}
                                    stepNumber={step.stepNumber}
                                    title={step.title}
                                    description={step.description}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="align-self-end d-none d-lg-block col-lg-2 col-xl-6">
                        <div className="process-image d-none d-xl-block">
                            <img loading="lazy" className="tilt-animate" src={ProcessImg} alt="image"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-3">
                        <div className="row">
                            {t('processSteps.steps', { returnObjects: true }).slice(2).map((step, index) => (
                                <ProcessStep
                                    key={index}
                                    stepNumber={step.stepNumber}
                                    title={step.title}
                                    description={step.description}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProcessStepArea;
