import React from 'react';

const AccordionItem = ({ id, title, content }) => (
    <div className="accordion-list-item">
        <div id={`heading${id}`}>
            <div className={`accordion-head ${id == 1 ? '' : 'collapsed'}`} data-bs-toggle="collapse"
                 data-bs-target={`#collapse${id}`} aria-expanded={`${id == 1 ? 'true' : 'false'}`}
                 aria-controls={`collapse${id}`}>
                <h3 className="accordion-title">{title}</h3>
            </div>
        </div>
        <div id={`collapse${id}`} className={`accordion-collapse collapse ${id == 1 ? 'show' : ''}`}
             aria-labelledby={`heading${id}`} data-bs-parent="#appointmentAreaStyle1FAQ">
            <div className="accordion-item-body">
                <p>{content}</p>
            </div>
        </div>
    </div>
);

const Accordion = ({ items }) => (
    <div className="accordion-box-wrapper" id="appointmentAreaStyle1FAQ">
        {items.map((item, index) => (
            <AccordionItem key={index} id={index + 1} title={item.title} content={item.content} />
        ))}
    </div>
);

export default Accordion;
