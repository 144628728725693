import React from 'react';
import CounterUp from "../component/CounterUp";

const CounterItem = ({ endValue, title, description }) => {
    return (
        <div className="counter-item">
            <div className="counter-title">
                <h1 className="number">
                    <CounterUp start={0} end={endValue} delay={10} />
                </h1>
                <p className="title">{title}</p>
            </div>
            {/*<div className="counter-content">*/}
            {/*    <p className="text">{description}</p>*/}
            {/*</div>*/}
        </div>
    );
};

export default CounterItem;
