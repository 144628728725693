import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import SliderItem from './SliderItem';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Slider1 from '../assets/images/slider/slider-1.jpg';
import Slider2 from '../assets/images/slider/slider-2.jpg';
import Home1FeatureSlider1 from '../assets/images/slider/home-1-feature-slider-1.png';
import Home1FeatureSlider2 from '../assets/images/slider/home-1-feature-slider-2.png';
import '../component-style/SliderArea.css'; // Import the CSS file

const SliderArea = () => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null); // Reference to the Slider component

    // Settings for the Slider component
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current), // Update current slide state after each change
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % 2); // Change 2 to the number of slides you have
        }, 5000); // Change slide every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(currentSlide); // Navigate to the current slide
        }
    }, [currentSlide]);

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <div className="slider-area style-1" style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}>
            {/* Slider component */}
            <Slider ref={sliderRef} {...settings}>
                {/* Slide 1 */}
                <SliderItem
                    backgroundImage={Slider1}
                    HomeFeatureSlider={Home1FeatureSlider1}
                    shortTitle={t('slider.slide1.shortTitle')}
                    title={t('slider.slide1.title')}
                    description={t('slider.slide1.description')}
                />
                {/* Slide 2 */}
                <SliderItem
                    backgroundImage={Slider2}
                    HomeFeatureSlider={Home1FeatureSlider2}
                    shortTitle={t('slider.slide2.shortTitle')}
                    title={t('slider.slide2.title')}
                    description={t('slider.slide2.description')}
                />
            </Slider>
            {/* Custom Controller Buttons with Arrow Icons */}
            <div className="slider-controls">
                <button onClick={prevSlide} className="slider-control-prev">
                    <FontAwesomeIcon icon={i18n.language === 'en' ? faArrowLeft : faArrowRight} className="arrow-icon" size="2x"/>
                </button>
                <button onClick={nextSlide} className="slider-control-next">
                    <FontAwesomeIcon icon={i18n.language === 'en' ? faArrowRight : faArrowLeft} className="arrow-icon" size="2x"/>
                </button>
            </div>
        </div>
    );
};

export default SliderArea;
