import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import PageBreadcrumb from "../component/PageBreadcrumb";
import BlogArea from "../component/BlogArea";
import Blog1 from '../assets/images/blog/b1.jpg'
const Blogs = () => {
    const pageTitle = 'مدونات';
    const breadcrumbLinks = [
        { label: 'الرئيسيه', url: '/' },
        { label: 'مدونات', url: '/about' }
    ];

    const blogPosts = [
        {
            id: 1,
            image: Blog1,
            category: 'Transportation',
            date: 'April 11, 2023',
            comments: 34,
            title: 'When the musics over turn off the light',
            excerpt: 'Hoyto olor in reprehenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto beatae vitae dicta sunt explicabo. Nemo enim ...',
            link: 'blog-details.html' // Replace with actual link
        },{
            id: 2,
            image: Blog1,
            category: 'Transportation',
            date: 'April 11, 2023',
            comments: 34,
            title: 'When the musics over turn off the light',
            excerpt: 'Hoyto olor in reprehenderit in voluptate velit esse cillum dolore eu fugiatmnis iste natus error sit tem accusa nulla pariatu mmodo consequat. Duis aute irure dolor in reprehenderit in voluptate se cillum dolore eu fugiat nulla paria hitecto beatae vitae dicta sunt explicabo. Nemo enim ...',
            link: 'blog-details.html' // Replace with actual link
        },
        // Add more blog post data here if needed
    ];


    return (
        <>
            <Header />
            <PageBreadcrumb pageTitle={pageTitle} breadcrumbLinks={breadcrumbLinks} />
            <BlogArea posts={blogPosts} />

            <Footer />

        </>
    );
};

export default Blogs;
