import React from 'react';
import homeFeaturePost01 from "../assets/images/blog/01_home_feature_post.jpg";
import homeFeaturePost02 from "../assets/images/blog/02_home_feature_post.jpg";
import homeFeaturePost03 from "../assets/images/blog/03_home_feature_post.jpg";
import homeFeaturePost04 from "../assets/images/blog/04_home_feature_post.jpg";
import {Link} from "react-router-dom";

const BlogPostCard = ({ title, date, image}) => (
    <div className="col-12 col-md-6 col-lg-6">
        <div className="post-card style-one">
            <div className="image">
                <img loading="lazy" src={image} alt="post-1"/>
            </div>
            <div className="content">
                <h2 className="title">
                    <Link to={'/'}>{title}</Link>
                </h2>
            </div>
            <p className="date-bottom">{date}</p>
        </div>
    </div>
);

const BlogPostList = ({ language }) => {
    const posts = language === 'en' ? [
        { title: 'A leading shipping company in the Kingdom', date: 'April 02, 2023', image: homeFeaturePost01 },
        { title: 'SMB Express is a name with a history', date: 'March 13, 2022', image: homeFeaturePost02 },
        { title: '50 success stories around the Kingdom', date: 'October 13, 2022', image: homeFeaturePost03 },
        { title: 'Reliable shipping services throughout the Kingdom', date: 'June 13, 2022', image: homeFeaturePost04 }
    ] : [
        { title: 'شركة شحن رائدة في المملكة', date: 'أبريل 02, 2023' , image: homeFeaturePost01},
        { title: 'SMB-Express إسم له تاريخ', date: 'مارس 13, 2022' , image: homeFeaturePost02},
        { title: '50 قصة نجاح حول المملكة', date: 'اكتوبر 13, 2022' , image: homeFeaturePost03},
        { title: 'خدمات شحن موثوقة في جميع أنحاء المملكة', date: 'يونيو 13, 2022' , image: homeFeaturePost04}
    ];

    return (
        <div className="row">
            {posts.map((post, index) => (
                <BlogPostCard key={index} title={post.title} image={post.image} date={post.date} />
            ))}
        </div>
    );
};

export default BlogPostList;
