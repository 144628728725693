import React from 'react';

const ServiceCard = ({ BgImage, IconImage, title, description }) => {
    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="info-card style-two" style={{backgroundImage: `url(${BgImage})`}}>
                <div className="info-card-inner">
                    <div className="content-wrapper">
                        <div className="title-wrapper">
                            <div className="icon">
                                <img loading="lazy" src={IconImage} alt=""/>
                            </div>
                            <h2 className="title">{title}</h2>
                        </div>
                        <div className="content">
                            <p className="desc">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
