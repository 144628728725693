import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutCardImg from "../assets/images/about/about-card-img.jpg";
import AboutCardImg2 from "../assets/images/about/about-card-img-2.png";
import Tropy from "../assets/images/icon/tropy.png";
import Track from "../assets/images/icon/track.png";
import {Link, useLocation} from "react-router-dom";

const AboutUsArea = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <div className="about-us-area style-1 overflow-hidden">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-6 col-xl-5">
                        <div className="about-image-card">
                            <div className="main-img-wrapper">
                                <div className="main-img-inner">
                                    <img loading="lazy" className="tilt-animate" src={AboutCardImg} alt="about card img" />
                                    <div className="img-card-wrapper image-one">
                                        <img loading="lazy" src={Tropy} alt="about card img" />
                                    </div>
                                    <div className="img-card-wrapper image-two">
                                        <img loading="lazy" src={Track} alt="about card img" />
                                    </div>
                                    <div className="img-card-wrapper image-three">
                                        <h1 className="year">40</h1>
                                        <h6 className="title">{t('about_us.title')}</h6>
                                    </div>
                                    <div className="img-card-wrapper image-four">
                                        <img loading="lazy" className="tilt-animate" src={AboutCardImg2} alt="about card img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-6 col-xl-7">
                        <div className="about-info-card">
                            <div className="about-info-content">
                                <div className="section-title">
                                    <span className="short-title">{t('about_us.title')}</span>
                                    <h2 className="title">{t('about_us.history')}</h2>
                                </div>
                                <div className="text">
                                    <p>{t('about_us.text')}</p>
                                </div>
                                <div className="quote-text">
                                    <p>{t('about_us.quote.text')}</p>
                                    <div className="quote-text-meta">
                                        <span className="divider"></span>
                                        <span className="name">{t('about_us.quote.meta.name')}</span>
                                        <span className="designation">{t('about_us.quote.meta.designation')}</span>
                                    </div>
                                </div>
                                {location.pathname != '/about' &&
                                <div className="btn-wrapper">
                                    <Link to="/about" className="theme-btn">{t('about_us.read_more')}<i className="fa-solid fa-angle-right"></i></Link>
                                    {/*<a href="#" className="theme-btn">{t('about_us.read_more')}<i className="fa-solid fa-angle-right"></i></a>*/}
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsArea;
