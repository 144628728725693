import React from 'react';

const TrackingContainer = ({trackingData, handleRemoveData}) => {

    const TrackingItems = ({ trackingData }) => {
        if (!trackingData || !trackingData.items) return null;
        return (
            <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                {trackingData.items[0].tracking_items.map((item, index) => (
                    <div key={index} className="vertical-timeline-item vertical-timeline-element">
                    <span className="vertical-timeline-element-icon bounce-in">
                        <i className="fa-solid fa-circle"></i>
                    </span>
                        <div className="vertical-timeline-element-content bounce-in">
                            <h4 className="timeline-title">{item.status_title}</h4>
                            <p>{item.message}</p>
                            <span className="vertical-timeline-element-date">{item.datetime.split('T')[0]}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={`tracking-data ${trackingData && 'show-data'}`} id="tracking-data">
            <div className="row d-flex justify-content-center mt-70 mb-70">
                <div className="col-md-9">
                    <button className="btn btn-link close-tracking-data" onClick={() => handleRemoveData(null)}><i
                        className="fal fa-times" aria-hidden="true"></i>
                    </button>

                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            <h5 className="card-title">{trackingData && trackingData.items[0].to_address.name }</h5>
                            <div
                                className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">

                                {trackingData && <TrackingItems trackingData={trackingData} />}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TrackingContainer;