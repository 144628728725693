import React from 'react';

const ProcessStep = ({ stepNumber, title, description }) => {
    return (
        <div className="col-12">
            <div className="process-step">
                <div className="icon">
                    <div className="count">
                        <span>{stepNumber}</span>
                    </div>
                </div>
                <div className="content">
                    <h2 className="title">{title}</h2>
                    <p className="desc">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default ProcessStep;
