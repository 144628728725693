import React, {useEffect, useState} from 'react';
import SocialMediaLinks from './SocialMediaLinks'; // Assuming you have a SocialMediaLinks component
import Logo from "../assets/images/logo/logo.png";
import Logo2 from "../assets/images/logo/logo-2.png";
import Phone from "../assets/images/icon/phone.png";
import Nav from "./Nav";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Header = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const {t} = useTranslation();

    const toggleClass = () => {
        setIsClosed(!isClosed);
    }
    useEffect(() => {
        const handleScroll = () => {
            const height = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);

            if (height > 1400) {
                if (window.pageYOffset > 300 && !isSticky) {
                    setIsSticky(true);
                } else if (window.pageYOffset <= 300 && isSticky) {
                    setIsSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isSticky]);

    return (
        <>
            <header className={`header-area`}>
                <div className="header-top">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-12 d-flex align-items-center justify-content-start justify-content-lg-end">
                                <div className="header-top-info">
                                    <div className="header-contact-info">
                                        <span>
                                            <span className="contact-info-item">
                                                <i className="fa-solid fa-location-dot"></i> {t('companyAddress')}
                                            </span>
                                        </span>
                                        <span>
                                            <a href="mailto:hello@smb.com">
                                                <i className="fa-solid fa-envelope"></i> {t('companyEmail')}
                                            </a>
                                        </span>
                                    </div>
                                    <div className="header-top-btn">
                                        <Link to="/contact">{t('contact')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`header-menu-area sticky-header ${isSticky ? 'sticky_menu animated fadeIn' : ''}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                                <div className="logo">
                                    <Link to="/" className="standard-logo">
                                        <img loading="lazy" src={Logo} alt="logo"/>
                                    </Link>
                                    <Link to="/" className="sticky-logo">
                                        <img loading="lazy" src={Logo2} width="120" alt="logo"/>
                                    </Link>
                                    <Link to="/" className="retina-logo">
                                        <img loading="lazy" src={Logo2} width="120" alt="logo"/>
                                    </Link>
                                </div>
                            </div>
                            <div
                                className="col-xl-9 col-lg-9 col-md-6 col-6 d-flex align-items-center justify-content-end">
                                <div className="menu d-inline-block">
                                    <Nav/>
                                </div>
                                <a href="tel:+123-456-7890" className="header-btn">
                                    <div className="icon-wrapper">
                                        <div className="icon">
                                            <img loading="lazy" src={Phone} alt="phone"/>
                                        </div>
                                    </div>
                                    <div className="content-wrapper">
                                        <span className="title">{t('header.ask_now')}</span>
                                        <span dir="ltr" className="text">{t('contactInfoPhone')}</span>
                                    </div>
                                </a>

                                <div className="mobile-menu-bar d-lg-none text-end">
                                    <a href="#" className="mobile-menu-toggle-btn" onClick={toggleClass}>
                                        <i className="fal fa-bars"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`menu-sidebar-area ${isClosed ? 'active' : ''}`}>
                <div className="menu-sidebar-wrapper text-center">
                    <div className="menu-sidebar-close">
                        <button className="menu-sidebar-close-btn" id="menu_sidebar_close_btn" onClick={toggleClass}>
                            <i className="fal fa-times"></i>
                        </button>
                    </div>

                    <div className="menu-sidebar-content text-center">
                        <div className="menu-sidebar-logo">
                            <Link tp={'/'}>
                                <img loading="lazy" src={Logo2} width="120" alt="logo"/>
                            </Link>
                        </div>
                        <div className="mobile-nav-menu">
                            <Nav/>
                        </div>
                        <div className="menu-sidebar-content ">
                            <div className="menu-sidebar-single-widget">
                                <h5 className="menu-sidebar-title">{t('header.contact_info_title')}</h5>
                                <div className="header-contact-info">
                                    <span><i className="fa-solid fa-location-dot"></i>{t('companyAddress')}</span>
                                    <span><a href="mailto:hello@transico.com"><i
                                        className="fa-solid fa-envelope"></i>{t('companyEmail')}</a></span>
                                    <span dir="ltr"><a href={`tel:${t('contactInfoPhone')}`}><i
                                        className="fa-solid fa-phone"></i>{t('contactInfoPhone')}</a></span>
                                </div>
                                <div className="social-profile">
                                    <SocialMediaLinks/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Header;
