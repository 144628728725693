import React from 'react';
import ListItem from './ListItem';
import Service1 from '../assets/images/service/05_service_1.jpg';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const WhyChooseUs = () => {
    const { t, i18n } = useTranslation(); // Initialize useTranslation hook

    // Define an array of objects with text for each list item
    const listItems = t('whyChooseUs.listItems', { returnObjects: true });

    return (
        <div className="why-choose-us-area style-3">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7">
                        <div className="service-list-wrapper ">
                            <div className="section-title mb-40">
                                <span className="short-title">{t('whyChooseUs.titleShort')}</span>
                                <h2 className="title">{t('whyChooseUs.title')}</h2>
                            </div>
                            <div className="section-content mb-40">
                                <p>{t('whyChooseUs.description')}</p>
                            </div>
                            <div className="row">
                                {listItems.map((item, index) => (
                                    <ListItem key={index} text={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="service-image mt-30 mt-lg-0">
                            <img loading="lazy" className="border-radius-12 tilt-animate" src={Service1} alt="service" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
